// (function() {
// 	const observer = new IntersectionObserver(entries=> {
// 		entries.forEach(entry=> {
// 			if(entry.isIntersecting) {
// 				let animationClasses = entry.target.dataset.animate || '';

// 				animationClasses = animationClasses.split(' ');

// 				animationClasses.forEach(classe => entry.target.classList.add(classe));

// 				observer.unobserve(entry.target);
// 			}
// 		})
// 	});
// 	const animatedElements = document.querySelectorAll('[data-animate]');

// 	animatedElements.forEach(el => observer.observe(el));
// })();
